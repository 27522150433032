import { createReducer } from '@dabapps/redux-create-reducer';
import { AxiosResponse } from 'axios';

import { XeroConnectionState } from './types';
import { GET_XERO_CONNECTION } from './actions';

export interface GetXeroConnectionSuccessAction {
  type: string;
  payload: AxiosResponse<XeroConnectionState>;
}

export const xeroConnectionState = createReducer(
  {
    [GET_XERO_CONNECTION.SUCCESS]: (
      _state: null | XeroConnectionState,
      action: GetXeroConnectionSuccessAction
    ) => action.payload.data,
  },
  null
);
