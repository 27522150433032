// actions.ts
import { makeAsyncActionSet, requestWithConfig } from '@dabapps/redux-requests';

import { RETHROW_OPTIONS } from '^/common/constants';

export const GET_XERO_CONNECTION = makeAsyncActionSet('GET_XERO_CONNECTION');
export const getXeroConnection = () =>
  requestWithConfig(
    GET_XERO_CONNECTION,
    {
      url: `/api/billing/xero/connection/`,
    },
    RETHROW_OPTIONS
  );

export const LOGIN_XERO = makeAsyncActionSet('LOGIN_XERO');
export const loginXero = () => {
  window.location.assign('/billing/xero/login');
};

export const EDIT_XERO_SETTINGS = makeAsyncActionSet('EDIT_XERO_SETTINGS');
export const editXeroSettings = () => {
  window.location.assign('/admin/billing/xeroconnection/');
};
