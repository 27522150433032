import { createReducer } from '@dabapps/redux-create-reducer';
import { AxiosResponse } from 'axios';

import { LawFirm } from './types';
import {
  LIST_LAW_FIRMS,
  GET_LAW_FIRM,
  EDIT_LAW_FIRM,
  CLEAR_LAW_FIRM,
} from './actions';
import { LIST_LAW_FIRMS_SELECT_LIST } from './actions-list';
import { DjangoList } from '^/common/types';

export interface ListLawFirmsSuccessAction {
  type: string;
  payload: AxiosResponse<DjangoList<LawFirm>>;
}

export interface GetEditLawFirmSuccessAction {
  type: string;
  payload: AxiosResponse<LawFirm>;
}

export interface ListLawFirmsSelectListSuccessAction {
  type: string;
  payload: AxiosResponse<{ id: string; name: string }[] | null>;
}

export const lawFirms = createReducer(
  {
    [LIST_LAW_FIRMS.SUCCESS]: (
      _state: null | DjangoList<LawFirm>,
      action: ListLawFirmsSuccessAction
    ) => action.payload.data,
    [CLEAR_LAW_FIRM]: () => null,
  },
  null
);

export const lawFirmsSelectList = createReducer(
  {
    [LIST_LAW_FIRMS_SELECT_LIST.SUCCESS]: (
      _state: { id: string; name: string }[] | null,
      action: ListLawFirmsSelectListSuccessAction
    ) => action.payload.data,
  },
  null
);

export const lawFirm = createReducer(
  {
    [GET_LAW_FIRM.SUCCESS]: (
      _state: LawFirm | null,
      action: GetEditLawFirmSuccessAction
    ) => action.payload.data,
    [EDIT_LAW_FIRM.SUCCESS]: (
      _state: LawFirm | null,
      action: GetEditLawFirmSuccessAction
    ) => action.payload.data,
  },
  null
);
