import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import {
  combineReducers,
  applyMiddleware,
  createStore,
  Dispatch,
  Middleware,
  MiddlewareAPI,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { reducer } from 'redux-form';
import thunk from 'redux-thunk';

import * as authReducers from '^/auth/reducers';
import { AnyThunkActionOrAction } from '^/common/types';
import * as navigationReducers from '^/navigation/reducers';
import * as lawFirms from '^/law-firms/reducers';
import * as mailingList from '^/law-firms/mailing-list/reducers';
import { StoreState } from '^/store/types';
import { CURRENT_USER } from '^/utils/template';
import * as formulizeReducers from '^/formulize/reducers';
import * as clients from '^/clients/reducers';
import * as modals from '^/modals/reducers';
import * as form from '^/form/reducers';
import * as billing from '^/billing/reducers';

export const history = createBrowserHistory();

const rootReducer = combineReducers<StoreState>({
  router: connectRouter(history),
  form: reducer,
  ...navigationReducers,
  ...authReducers,
  ...lawFirms,
  ...formulizeReducers,
  ...mailingList,
  ...clients,
  ...modals,
  ...form,
  ...billing,
});

const middleware: Array<Middleware<{}, StoreState>> = [
  thunk,
  routerMiddleware(history),
  <S>(api: MiddlewareAPI<Dispatch, S>) => (next: Dispatch) => (
    action: AnyThunkActionOrAction
  ) =>
    typeof action === 'function'
      ? next(action(api.dispatch, api.getState, undefined))
      : next(action),
];

export const store = createStore(
  rootReducer,
  {
    currentUser: CURRENT_USER,
  },
  composeWithDevTools(applyMiddleware(...middleware))
);
