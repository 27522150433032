import React, { memo } from 'react';

export interface LogoProps {
  className?: string;
}

const Logo = ({ className }: LogoProps) => (
  <img
    src="../static/images/logo.png"
    className={className}
    alt="Nova Engage"
  />
);

export default memo(Logo);
