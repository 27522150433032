import { Dispatch } from 'redux';

const TIME_BEFORE_REDIRECT = 5000;

export const COMPLETE_FORM = 'COMPLETE_FORM';
export const REDIRECTED = 'REDIRECTING';

export const completeForm = () => (dispatch: Dispatch) => {
  dispatch({ type: COMPLETE_FORM });

  setTimeout(() => {
    dispatch({ type: REDIRECTED });
    window.location.assign('https://novalaw.co.uk/engage/60-sec-feedback/');
  }, TIME_BEFORE_REDIRECT);
};
