import { makeAsyncActionSet, requestWithConfig } from '@dabapps/redux-requests';

import { RETHROW_OPTIONS } from '^/common/constants';

export const LIST_LAW_FIRMS_SELECT_LIST = makeAsyncActionSet(
  'LIST_LAW_FIRMS_SELECT_LIST'
);
export const listLawFirmsSelectList = () =>
  requestWithConfig(
    LIST_LAW_FIRMS_SELECT_LIST,
    {
      url: '/api/law-firm/select-list',
    },
    RETHROW_OPTIONS
  );
