import { makeAsyncActionSet, requestWithConfig } from '@dabapps/redux-requests';
import queryString from 'query-string';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { push } from 'connected-react-router';

import { StoreState } from '^/store/types';
import { CreateEditLawFirmFormData } from './types';
import { serializeLawFirmFormData } from './utils';
import { RETHROW_OPTIONS } from '^/common/constants';

export const LIST_LAW_FIRMS = makeAsyncActionSet('LIST_LAW_FIRMS');
export const listLawFirms = (params: { page: number; search: string }) => {
  const query = queryString.stringify({
    ...params,
    search: params.search.trim() || undefined,
  });
  const prefixedQuery = query ? `?${query}` : '';

  return requestWithConfig(
    LIST_LAW_FIRMS,
    {
      url: `/api/law-firm/${prefixedQuery}`,
    },
    RETHROW_OPTIONS
  );
};

export const GET_LAW_FIRM = makeAsyncActionSet('GET_LAW_FIRM');
export const getLawFirm = (lawFirmId: string) =>
  requestWithConfig(
    GET_LAW_FIRM,
    {
      url: `/api/law-firm/${lawFirmId}/`,
    },
    RETHROW_OPTIONS
  );

export const CLEAR_LAW_FIRM = 'CLEAR_LAW_FIRM';
export const clearLawFirm = () => ({ type: CLEAR_LAW_FIRM });

export const CREATE_LAW_FIRM = makeAsyncActionSet('CREATE_LAW_FIRM');
export const createLawFirm = (data: Partial<CreateEditLawFirmFormData>) => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>
) =>
  dispatch(
    requestWithConfig(
      CREATE_LAW_FIRM,
      {
        url: '/api/law-firm/',
        method: 'POST',
        data: serializeLawFirmFormData(data),
      },
      RETHROW_OPTIONS
    )
  ).then(() => {
    dispatch(push('/'));
  });

export const EDIT_LAW_FIRM = makeAsyncActionSet('EDIT_LAW_FIRM');
export const editLawFirm = (
  lawFirmId: string,
  data: Partial<CreateEditLawFirmFormData>
) => (dispatch: ThunkDispatch<StoreState, unknown, AnyAction>) =>
  dispatch(
    requestWithConfig(
      EDIT_LAW_FIRM,
      {
        url: `/api/law-firm/${lawFirmId}/`,
        method: 'PATCH',
        data: serializeLawFirmFormData(data),
      },
      RETHROW_OPTIONS
    )
  );
