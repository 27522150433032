import { Nav as RoeNav, NavItem } from '@dabapps/roe';
import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { logout } from '^/auth/actions';
import { closeSideBar } from '^/navigation/actions';
import { StoreState } from '^/store/types';

const MATCHES_LAW_FIRMS = /^\/law-firms/;
const MATCHES_CLIENTS = /^\/clients/;
const MATCHES_BILLING = /^\/billing/;
const MATCHES_LOGIN = /^\/login$/;

type RouteProps = RouteComponentProps<{}>;

interface OwnProps {
  className?: string;
}

type StateProps = Pick<StoreState, 'currentUser'>;

type DispatchProps = ResolveThunks<{
  onClickLogout: typeof logout;
  onClickCloseSideBar: typeof closeSideBar;
}>;

type Props = OwnProps & RouteProps & StateProps & DispatchProps;

const Nav: React.FunctionComponent<Props> = props => (
  <RoeNav className={props.className}>
    {props.currentUser ? (
      <>
        {props.currentUser.is_staff && (
          <>
            <NavItem active={MATCHES_LAW_FIRMS.test(props.location.pathname)}>
              <Link onClick={props.onClickCloseSideBar} to="/law-firms">
                Law Firms
              </Link>
            </NavItem>
            <NavItem active={MATCHES_CLIENTS.test(props.location.pathname)}>
              <Link onClick={props.onClickCloseSideBar} to="/clients">
                Clients
              </Link>
            </NavItem>
            <NavItem active={MATCHES_BILLING.test(props.location.pathname)}>
              <Link onClick={props.onClickCloseSideBar} to="/billing">
                Billing
              </Link>
            </NavItem>
          </>
        )}
        {!props.currentUser.is_staff && (
          <>
            <NavItem>
              <a
                href="https://novalaw.co.uk/engage/privacy-policy/"
                target="_blank"
              >
                Privacy Policy
              </a>
            </NavItem>
            <NavItem>
              <a
                href="https://novalaw.co.uk/engage/engage-support/"
                target="_blank"
              >
                Support
              </a>
            </NavItem>
          </>
        )}

        <NavItem>
          <a onClick={props.onClickLogout}>Log out</a>
        </NavItem>
      </>
    ) : (
      <>
        <NavItem>
          <a
            href="https://novalaw.co.uk/engage/privacy-policy/"
            target="_blank"
          >
            Privacy Policy
          </a>
        </NavItem>
        <NavItem>
          <a
            href="https://novalaw.co.uk/engage/engage-support/"
            target="_blank"
          >
            Support
          </a>
        </NavItem>
        <NavItem active={MATCHES_LOGIN.test(props.location.pathname)}>
          <Link onClick={props.onClickCloseSideBar} to="/login">
            Log in
          </Link>
        </NavItem>
      </>
    )}
  </RoeNav>
);

const mapStateToProps = ({ currentUser }: StoreState): StateProps => {
  return {
    currentUser,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    onClickLogout: logout,
    onClickCloseSideBar: closeSideBar,
  })(Nav)
);
